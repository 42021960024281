@import "./variable.module";

.spinner-border {
  --bs-spinner-border-width: 0.15em;
  --bs-spinner-animation-speed: 0.95s;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  border: var(--bs-spinner-border-width) solid $primary;
  border-right-color: transparent;
}

.mainsidebar.closeSidebar+.DashboardWrapper {
  @media (max-width:1250px) {
    width: calc(100% - 0px);
  }
}

.mainsidebar+.DashboardWrapper {
  @media (max-width:$md) {
    width: calc(100% - 0px);
  }
}

.DashboardWrapper {
  width: calc(100% - 250px);

  @media (max-width: $xxl) {
    width: calc(100% - 190px);
  }
}

.whiteSpace {
  white-space: nowrap;

  @media(max-width:$md) {
    white-space: inherit;
  }
}

.cursor {
  cursor: pointer;
}

// border bottom css
.borderBottom {
  border-bottom: 1px solid $gray-200;
}

.form-select {
  font-size: 0.875rem;
  background-color: $gray-100;
  border: 1px solid $gray-500;
  padding: 11px 12px;
  min-width: 107px;
  width: auto;

  @media (max-width: $xxl) {
    padding: 6px 12px;
  }
}
// form css
form {
  .form-label {
    font-size: 1rem;
    margin-bottom: 12px;
  }

  .form-control {
    border-radius: 8px;
    border: 1px solid $gray-400;
    background: $white;
    padding: 12px 10px;
  }
}
.form-check-input {
  &:checked {
    background-color: $primary;
    border-color: $primary;
  }

  border: 1.3px solid $primary;

  &:focus {
    border-color: $primary;
    outline: 0;
    box-shadow: none;
  }
}

// full page loader css
.overlayLoader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(0 0 0 / 20%);
  position: absolute;
  top: 0;
  z-index: 99;
  left: 0;

  .spinner-border {
    --bs-spinner-border-width: 0.35em;
    --bs-spinner-width: 3.5rem;
    --bs-spinner-height: 3.5rem;
    border: var(--bs-spinner-border-width) solid $primary;
    border-right-color: transparent;
  }
}



