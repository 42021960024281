@import "../config/variable.module.scss";

body {
    overflow-y: auto;
    width: 100vw;
    overflow-x: hidden;
}

.surveyForm-wrapper {
    background-color: $white;

    .banner-txt {
        margin-top: 3rem;

        @media(max-width:1650px) {
            margin-top: 2rem;
        }

        @media(max-width:1470px) {
            margin-top: 1.1rem;
        }

        @media(max-width:1280px) {
            margin-top: 1.5rem;
        }
    }

    form {
        img {
            position: absolute;
            z-index: 9;
            top: 51px;
            left: 11px;
        }

        .form-control {
            border-radius: 4px;
            border: 1px solid $gray-400;
            background: $white;
            padding: 12px 10px 12px 43px;
            position: relative;
            height: 54px;
            margin-bottom: 4px;

            &:focus {
                border: 1px solid $primary;
                box-shadow: none;
            }

            &:focus-visible {
                border: 1px solid $primary;
            }
        }

        .phoneNumber {
            padding: 12px 10px 12px 62px;
        }

        .countryCode {
            position: absolute;
            z-index: 1;
            top: 53px;
            left: 40px;
            font-size: 14px;
        }

        .message {
            top: 55px;
        }
    }
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px $white inset !important;
    }

    .operational-info {
        .form-check {
            font-size: 1rem;
        }

        .fuelType {
            margin-bottom: 0px !important;
        }

        .flex-item:first-child {
            margin-left: 0;
        }

        .flex-item:last-child {
            margin-right: 0;
        }

        .form-control {
            padding: 12px 10px 12px 10px !important;
        }

        .form-check {
            display: flex;
            align-items: center;
            gap: 6px;
        }

        .form-check-input {
            width: 1.05rem;
            height: 1.05rem;
            margin-bottom: 5px;
            &:checked {
                margin-bottom: 5px;
                background-color: $primary;
                border-color: $primary;
            }
        }
    }

    .btn-blue {
        background-color: $primary;
        border: 1px solid $primary;
        color: $white;
        border-radius: 4px;
        padding: 12px 30px;

        img {
            filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(216deg) brightness(103%) contrast(101%);
            width: 15px;
            position: unset;
        }
    }

    .btn-blue-outline {
        background-color: $white;
        border: 1px solid $primary;
        color: $primary;
        border-radius: 4px;
        padding: 12px 30px;

        img {
            transform: rotate(180deg);
            width: 15px;
            position: unset;
        }
    }

    .survey-banner {
        background: url('../../assets/images/survey/banner.png');
        background-repeat: no-repeat;
        height: 400px;
        background-size: 100%;
        color: $white;

        @media(max-width:1920px) {
            height: 331px;
        }

        @media(max-width:1650px) {
            height: 286px
        }

        @media (max-width:1550px) {
            height: 269px;
        }

        @media (max-width:1260px) {
            height: 252px;
        }

        @media(max-width:996px) {
            background: url('../../assets/images/survey/banner.png') $blue;
            height: 178px;
            margin-bottom: 10px;
        }

        .scope {
            @media (max-width:1550px) {
                width: 130px;
            }
        }

        .pepsi {
            padding-right: 30px;
            padding-top: 10px;

            @media (max-width:1550px) {
                padding-right: 27px;
                width: 84px;
            }

            @media (max-width:1300px) {
                padding-right: 24px;
                width: 65px;
            }
        }
    }
}

.successmodal{
    height: calc(100vh - 68px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.mx-width-svg{
    width: 96px;
}